import React from 'react'
import '../styles/Career.css';
const Careers = () => {
  return <>
 <div className="contact-container">
      {/* <img src={img1} alt="Slide 1" className="bg11" /> */}
        <div className="bg2"></div>
        <div className="overlay"></div>
        
      <div className="carousel-textt">
  <div class="content-container">
    <div class="content">CAREER</div>
    <div class="navigation">
      <span>HOME</span> / <span class='contact'>CAREER</span>
    </div>
  </div>
  <div class="arrow-box"></div>
</div>

       
      </div>

  <div className="office-info">
        <h2>AL WAQEEFA DMCC</h2>
        <div className="office">
          
          
          <p>**No Openings at the moment**</p>
          
        </div>
       
      </div>
  
  </>;
}

export default Careers