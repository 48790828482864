// import React from 'react';
// import "../styles/Header1.css";
// import { Link } from "react-router-dom";
// import { FaSearch } from 'react-icons/fa';

// const Header1 = () => {
//   return (
//     <div style={{ display: 'flex', justifyContent: 'space-between' ,boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)'}}>
      
//      <nav> <Link to='/' className='heading1'>OGMC LLC</Link>
//      </nav>
//       <nav>
        
//         <Link to="./about">OGMCLLC</Link>
//         <Link to="./contact">Contact Us</Link>
//         <Link to="./careers" style={{ fontWeight: 'bold' }}>Careers</Link>
//         <Link><FaSearch /></Link>
//       </nav>
//     </div>
//   );
// }

// export default Header1;



import React from 'react';
import "../styles/Header1.css";
import { Link } from "react-router-dom";
import { FaSearch } from 'react-icons/fa';

const Header1 = () => {
  return <>
  <div>
    <div className="header-container1">
      
      <nav className="logo-nav1">
        <Link to='/' className='heading1'>OGMC LLC</Link>
      </nav>
      <nav className="links-nav1">
        <Link to="/about">About Us</Link>
        <Link to="/contact">Contact Us</Link>
        <Link to="/careers" className="bold-link1">Careers</Link>
        <Link to="/search"><FaSearch /></Link>
      </nav>
      
    </div>
    </div>
    </>;
}

export default Header1;
