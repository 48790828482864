// import React from 'react'
// import '../styles/About.css';

// const Careers = () => {
//   return <>
//   <div className="contact-container">
//     <div className="background-image1"></div>
//     <div className="overlay"></div>
  
//     <div className="content">ABOUT</div>
//       <div className="navigation">
      
//         <span>HOME</span> / <span className='contact'>ABOUT</span>
      
//     </div>
//     </div>
 
// <div className='glass'>
//   <div className="office-info">
//         <h2 style={{ marginLeft: '730px' }}>OGMCLLC</h2>
//         <h2 style={{ marginLeft: '550px' }}>Our Management has Decades of Experience
// </h2>
//  </div>
//         <div className="office">
          
          
//           <p>From its origin in 2006, OGMCLLC has been part of Al Hijab International and later in 2020 OGMCLLC emerged as a separate entity and new brand and as a global service provider specializing in the Trainings, Management Consulting, Payroll and flexible deployment of specialists to the international oil & gas, Power & Energy including renewable energy, engineering and construction, automotive, manufacturing and other related industries.

// Today, Octane is a strong global brand. Our annual turnover is USD 1.5 million. Octane International Energy Co. Ltd has a plan to enlist with Dubai Stock Exchange by 2025.

// At OGMC LLC, we are committed to deliver the superlative solutions to our worthy clients with assurance of quality and their specific needs. We have been working in the field of HR Services, including Manpower Recruitment, Employee Outsourcing, Payroll and Training & Development with global integration and market mix.

// Your inquiry and response on this subject will be highly appreciated and will be addressed with great care.
// </p>
          
//         </div>
//         </div>
       
     
  
//   </>;
// }

// export default Careers


import React from 'react';
import '../styles/About.css';

const Careers = () => {
  return (
    <>
     <div className="contact-container">
      {/* <img src={img1} alt="Slide 1" className="bg11" /> */}
        <div className="bg2"></div>
        <div className="overlay"></div>
        
      <div className="carousel-textt">
  <div class="content-container">
    <div class="content">ABOUT</div>
    <div class="navigation">
      <span>HOME</span> / <span class='contact'>ABOUT</span>
    </div>
  </div>
  <div class="arrow-box"></div>
</div>

       
      </div>
      <div className='glass'>
        <div className="office-info12">
          <h2 className="title1">OGMCLLC</h2>
          <h2 className="title2">Our Management has Decades of Experience</h2>
        </div>
        <div className="office">
          <p>
            From its origin in 2006, OGMCLLC has been part of Al Hijab International and later in 2020 OGMCLLC emerged as a separate entity and new brand and as a global service provider specializing in the Trainings, Management Consulting, Payroll and flexible deployment of specialists to the international oil & gas, Power & Energy including renewable energy, engineering and construction, automotive, manufacturing and other related industries.
            <br /><br />
            Today, Octane is a strong global brand. Our annual turnover is USD 1.5 million. Octane International Energy Co. Ltd has a plan to enlist with Dubai Stock Exchange by 2025.
            <br /><br />
            At OGMC LLC, we are committed to deliver the superlative solutions to our worthy clients with assurance of quality and their specific needs. We have been working in the field of HR Services, including Manpower Recruitment, Employee Outsourcing, Payroll and Training & Development with global integration and market mix.
            <br /><br />
            Your inquiry and response on this subject will be highly appreciated and will be addressed with great care.
          </p>
        </div>
      </div>
    </>
  );
}

export default Careers;
