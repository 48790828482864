






import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Header.css';
import logo from '../assets/oct.png';

const Header = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`header-container ${isSticky ? 'sticky' : ''}`}>
      <nav className="logo-nav">
        <Link to="/">
          <img src={logo} alt="OGMC LLC Logo" />
        </Link>
      </nav>
      <nav className="links-nav">
      <Link to="/services">Services</Link>
      <Link to="/market">Markets Served</Link>
        <Link 
          to="/products"
          onMouseEnter={() => setShowDropdown(true)}
          onMouseLeave={() => setShowDropdown(false)}
        >
          Products
          {/* {showDropdown && (
            <div className="dropdown-content">
              <Link to="/oil">Oil and Gas</Link>
              <Link to="#">Petrochemicals</Link>
              <Link to="#">Pumps, Engines & Spare Parts</Link>
              <Link to="#">Heavy Equipment & Machinery Spare Parts</Link>
            </div>
          )} */}
        </Link>
        
        <Link to="/news">News & Events</Link>
      </nav>
    </div>
  );
};

export default Header;



