
// import React, { useState } from 'react';
// import '../styles/Services.css';
// import { Link } from 'react-router-dom';
// import Slider from 'react-slick';
// import img1 from '../assets/fin.jpg'
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';

// const Services = () => {
//   const [selectedService, setSelectedService] = useState(null);

//   const handleServiceClick = (serviceName) => {
//     setSelectedService(serviceName);
//   };

//   const handleBackClick = () => {
//     setSelectedService(null);
//   };

//   const carouselSettings = {
//     dots: false,
//     infinite: false,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     autoplay: false,
//   };

//   return (
//     <>
//       <div className="contact-container">
//       {/* <img src={img1} alt="Slide 1" className="bg11" /> */}
//         <div className="bg1"></div>
//         <div className="overlay"></div>
        
//       <div className="carousel-textt">
//   <div class="content-container">
//     <div class="content">SERVICES</div>
//     <div class="navigation">
//       <span>HOME</span> / <span class='contact'>SERVICES</span>
//     </div>
//   </div>
//   <div class="arrow-box"></div>
// </div>

       
//       </div>
//       <div className='glass1'>
//         {selectedService ? (
//           <div className="service-details">
//             <h2>{selectedService}</h2>
//             <Slider {...carouselSettings}>
//               {/* Add service descriptions */}
//               {selectedService === "Corporate Governance" && (
//                 <>
//                   <div>
//                     <p>Business set up in UAE</p>
//                     <p>Main land</p>
//                     <p>Freezone</p>
//                     <p>Offshore</p>
//                     <p>Bank account in UAE</p>
//                     <p>Listing of company with banks</p>
//                     <p>Business tour to UAE</p>
//                     <p>UAE Visa support</p>
//                     <p>Resident visa by property</p>
//                     <p>Relocation to UAE</p>
//                     <p>ISO certifications</p>
//                     <p>PRO services</p>
//                     <p>Golden visa</p>
//                   </div>
//                 </>
//               )}
//               {selectedService === "Accounting & Finance" && (
//                 <>
//                   <div>
//                     <p>Book keeping</p>
//                     <p>Financial accounting & reporting</p>
//                     <p>Internal audit</p>
//                     <p>External audit</p>
//                     <p>Internal control mechanism</p>
//                     <p>Cost audit</p>
//                     <p>VAT</p>
//                     <p>Corporate tax</p>
//                     <p>Corporate Finance</p>
//                     <p>Fixed Asset Management</p>
//                     <p>Business plans</p>
//                     <p>Market studies</p>
//                     <p>Feasibility studies</p>
//                     <p>Cashflow management</p>
//                   </div>
//                 </>
//               )}
//               {selectedService === "HR Consultancy" && (
//                 <>
//                   <div>
//                     <p>Recruitment services</p>
//                     <p>Secondments</p>
//                     <p>Payroll & SOS</p>
//                     <p>Global mobility</p>
//                     <p>Oil & gas professional & technical competency trainings</p>
//                     <p>Contracting services</p>
//                     <p>Insurance services management</p>
//                     <p>End of service benefits management of employees</p>
//                   </div>
//                 </>
//               )}
//               {selectedService === "Trading & Provision of goods" && (
//                 <>
//                   <div>
//                     <p>Complete office setup</p>
//                     <p>Provision of furniture & fittings</p>
//                     <p>Installation of software & hardware security systems</p>
//                     <p>Installation of fire extinguishers & maintenance of fire alarms</p>
//                     <p>General office supplies</p>
//                     <p>HVAC maintenance services</p>
//                   </div>
//                 </>
//               )}
//             </Slider>
//             <button className='btn' onClick={handleBackClick}>Back</button>
//           </div>
//         ) : (
//           <div className="office-info1">
//             <h2 className="title1">OGMCLLC</h2>
//             <h2 className="title2">OGMCLLC Service Range</h2>
//           </div>
//         )}
//         {!selectedService && (
//           <div className="office">
//             <p>
//               <Link to="#" onClick={() => handleServiceClick("Corporate Governance")}>1. Corporate Governance</Link>
//               <br /><br />
//               <Link to="#" onClick={() => handleServiceClick("Accounting & Finance")}>2. Accounting & Finance</Link>
//               <br /><br />
//               <Link to="#" onClick={() => handleServiceClick("HR Consultancy")}>3. HR Consultancy</Link>
//               <br /><br />
//               <Link to="#" onClick={() => handleServiceClick("Trading & Provision of goods")}>4. Trading & Provision of goods</Link>
//             </p>
//           </div>
//         )}
//       </div>
//     </>
//   );
// }

// export default Services;


  import React, { useState } from 'react';
  import { FaBuilding, FaLandmark, FaMapMarkerAlt, FaShip, FaUniversity, FaListAlt, FaPlane, FaRegAddressCard, FaSuitcase, FaExchangeAlt, FaCertificate, FaClipboardCheck, FaMoneyBillAlt,  FaCalculator, FaMoneyCheck, FaPeopleCarry, FaHandshake, FaChalkboardTeacher, FaHammer, FaFileInvoiceDollar, FaCashRegister, FaBoxOpen, FaToolbox, FaBoxes} from 'react-icons/fa';

  import '../styles/Services.css';
  import { Link } from 'react-router-dom';
  import Slider from 'react-slick';
  import 'slick-carousel/slick/slick.css';
  import 'slick-carousel/slick/slick-theme.css';

  // Example icon imports (use your actual icons)
  import CorporateIcon from '../assets/fin.jpg';
  import FinanceIcon from '../assets/finance.jpg';
  import HRIcon from '../assets/social.jpg';
  import TradingIcon from '../assets/oil.jpg';

  const Services = () => {
    const [selectedService, setSelectedService] = useState(null);
    const [activeIndex, setActiveIndex] = useState(null);

    const handleServiceClick = (serviceName) => {
      setSelectedService(serviceName);
      setActiveIndex(null);
    };

    const handleBackClick = () => {
      setSelectedService(null);
    };

    const handleItemClick = (index) => {
      setActiveIndex(index);
    };

    const carouselSettings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
    };

    // const services = {
    //   "Corporate Governance": [
    //     { name: "Business set up in UAE", icon: <FaBuilding className="icon1"/> },
    //     { name: "Main land", icon: <FaLandmark className="icon1"/> },
    //     { name: "Freezone", icon: <FaMapMarkerAlt className="icon1"/> },
    //     { name: "Offshore", icon: <FaShip className="icon1"/> },
    //     { name: "Bank account in UAE", icon: <FaUniversity className="icon1"/> },
    //     { name: "Listing of company with banks", icon: <FaListAlt className="icon1"/> },
    //     { name: "Business tour to UAE", icon: <FaPlane className="icon1"/> },
    //     { name: "UAE Visa support", icon: <FaRegAddressCard className="icon1"/> },
    //     { name: "Resident visa by property", icon: <FaSuitcase className="icon1"/> },
    //     { name: "Relocation to UAE", icon: <FaExchangeAlt className="icon1"/> },
    //     { name: "ISO certifications", icon: <FaCertificate className="icon1"/> },
    //     { name: "PRO services", icon: <FaClipboardCheck className="icon1"/> },
    //     { name: "Golden visa", icon: <FaMoneyBillAlt className="icon1"/> }
    //   ],
    //   "Accounting & Finance": [
    //     { name: "Book keeping", icon: <FaCalculator className="icon1"/> },
    //     { name: "Financial accounting & reporting", icon: <FaMoneyCheck className="icon1"/> },
    //     { name: "Internal audit", icon: <FaChalkboardTeacher className="icon1"/> },
    //     { name: "External audit", icon: <FaChalkboardTeacher className="icon1"/> },
    //     { name: "Internal control mechanism", icon: <FaHammer className="icon1"/> },
    //     { name: "Cost audit", icon: <FaHammer className="icon1"/> },
    //     { name: "VAT", icon: <FaFileInvoiceDollar className="icon1"/> },
    //     { name: "Corporate tax", icon: <FaFileInvoiceDollar className="icon1"/> },
    //     { name: "Corporate Finance", icon: <FaCashRegister className="icon1"/> },
    //     { name: "Fixed Asset Management", icon: <FaBoxOpen className="icon1"/> },
    //     { name: "Business plans", icon: <FaToolbox className="icon1"/> },
    //     { name: "Market studies", icon: <FaToolbox className="icon1"/> },
    //     { name: "Feasibility studies", icon: <FaToolbox className="icon1"/> },
    //     { name: "Cashflow management", icon: <FaToolbox className="icon1"/> }
    //   ],
    //   "HR Consultancy": [
    //     { name: "Recruitment services", icon: <FaPeopleCarry className="icon1"/> },
    //     { name: "Secondments", icon: <FaPeopleCarry className="icon1"/> },
    //     { name: "Payroll & SOS", icon: <FaMoneyCheck className="icon1"/> },
    //     { name: "Global mobility", icon: <FaHandshake className="icon1"/> },
    //     { name: "Oil & gas professional & technical competency trainings", icon: <FaChalkboardTeacher className="icon1"/> },
    //     { name: "Contracting services", icon: <FaHammer className="icon1"/> },
    //     { name: "Insurance services management", icon: <FaFileInvoiceDollar className="icon1"/> },
    //     { name: "End of service benefits management of employees", icon: <FaMoneyCheck className="icon1"/> }
    //   ],
    //   "Trading & Provision of goods": [
    //     { name: "Complete office setup", icon: <FaBoxOpen className="icon1"/> },
    //     { name: "Provision of furniture & fittings", icon: <FaBoxOpen className="icon1"/> },
    //     { name: "Installation of software & hardware security systems", icon: <FaToolbox className="icon1"/> },
    //     { name: "Installation of fire extinguishers & maintenance of fire alarms", icon: <FaToolbox className="icon1"/> },
    //     { name: "General office supplies", icon: <FaBoxOpen className="icon1"/> },
    //     { name: "HVAC maintenance services", icon: <FaToolbox className="icon1"/> }
    //   ]
    // };

    const services = {
      "Corporate Governance": [
        { name: "Business set up in UAE", icon: <FaBuilding className="icon1"/> },
        { name: "Main land", icon: <FaLandmark className="icon1"/> },
        { name: "Freezone", icon: <FaMapMarkerAlt className="icon1"/> },
        { name: "Offshore", icon: <FaShip className="icon1"/> },
        { name: "Bank account in UAE", icon: <FaUniversity className="icon1"/> },
        { name: "Listing of company with banks", icon: <FaListAlt className="icon1"/> },
        { name: "Business tour to UAE", icon: <FaPlane className="icon1"/> },
        { name: "UAE Visa support", icon: <FaRegAddressCard className="icon1"/> },
        { name: "Resident visa by property", icon: <FaSuitcase className="icon1"/> },
        { name: "Relocation to UAE", icon: <FaExchangeAlt className="icon1"/> },
        { name: "ISO certifications", icon: <FaCertificate className="icon1"/> },
        { name: "PRO services", icon: <FaClipboardCheck className="icon1"/> },
        { name: "Golden visa", icon: <FaMoneyBillAlt className="icon1"/> }
      ],
      "Accounting & Finance": [
        { name: "Book keeping", icon: <FaCalculator className="icon1"/> },
        { name: "Financial accounting & reporting", icon: <FaMoneyCheck className="icon1"/> },
        { name: "Internal audit", icon: <FaChalkboardTeacher className="icon1"/> },
        { name: "External audit", icon: <FaChalkboardTeacher className="icon1"/> },
        { name: "Internal control mechanism", icon: <FaHammer className="icon1"/> },
        { name: "Cost audit", icon: <FaHammer className="icon1"/> },
        { name: "VAT", icon: <FaFileInvoiceDollar className="icon1"/> },
        { name: "Corporate tax", icon: <FaFileInvoiceDollar className="icon1"/> },
        { name: "Corporate Finance", icon: <FaCashRegister className="icon1"/> },
        { name: "Fixed Asset Management", icon: <FaBoxOpen className="icon1"/> },
        { name: "Business plans", icon: <FaToolbox className="icon1"/> },
        { name: "Market studies", icon: <FaToolbox className="icon1"/> },
        { name: "Feasibility studies", icon: <FaToolbox className="icon1"/> },
        { name: "Cashflow management", icon: <FaToolbox className="icon1"/> }
      ],
      "HR Consultancy": [
        { name: "Recruitment services", icon: <FaPeopleCarry className="icon1"/> },
        { name: "Secondments", icon: <FaPeopleCarry className="icon1"/> },
        { name: "Payroll & SOS", icon: <FaMoneyCheck className="icon1"/> },
        { name: "Global mobility", icon: <FaHandshake className="icon1"/> },
        { name: "Oil & gas professional & technical competency trainings", icon: <FaChalkboardTeacher className="icon1"/> },
        { name: "Contracting services", icon: <FaHammer className="icon1"/> },
        { name: "Insurance services management", icon: <FaFileInvoiceDollar className="icon1"/> },
        { name: "End of service benefits management of employees", icon: <FaMoneyCheck className="icon1"/> }
      ],
      "Trading & Provision of goods": [
        { name: "Complete office setup", icon: <FaBoxOpen className="icon1"/> },
        { name: "Provision of furniture & fittings", icon: <FaBoxOpen className="icon1"/> },
        { name: "Installation of software & hardware security systems", icon: <FaToolbox className="icon1"/> },
        { name: "Installation of fire extinguishers & maintenance of fire alarms", icon: <FaToolbox className="icon1"/> },
        { name: "General office supplies", icon: <FaBoxOpen className="icon1"/> },
        { name: "HVAC maintenance services", icon: <FaToolbox className="icon1"/> }
      ]
    };

    return (
      <>
        <div className="contact-container">
          <div className="bg1"></div>
          <div className="overlay"></div>

          <div className="carousel-textt">
            <div className="content-container">
              <div className="content">SERVICES</div>
              <div className="navigation">
                <span>HOME</span> / <span className='contact'>SERVICES</span>
              </div>
            </div>
            <div className="arrow-box"></div>
          </div>
        </div>
        <div className='glass1'>
          {selectedService ? (
            <div className="service-details">
              <h2>{selectedService}</h2>
              {/* <div className="services-grid">
                {services[selectedService].map((service, index) => (
                  <div 
                    key={index} 
                    className={`service-item ${activeIndex === index ? 'active' : ''}`} 
                    onClick={() => handleItemClick(index)}
                  >
                    <img src={service.icon} alt={`${service.name} icon`} className={`service-icon ${activeIndex === index ? 'active' : ''}`} />
                    <p className="service-name">{service.name}</p>
                  </div>
                ))}
              </div> */}
              <div className="services-grid">
  {services[selectedService].map((service, index) => (
    <div 
      key={index} 
      className={`service-item ${activeIndex === index ? 'active' : ''}`} 
      onClick={() => handleItemClick(index)}
    >
      {service.icon /* No need for img tag here */}
      <p className="service-name">{service.name}</p>
    </div>
  ))}
</div>
              {activeIndex !== null && (
                <div className="service-description">
                  {/* <p>{services[selectedService][activeIndex].name} - Details about this service will be displayed here.</p>
                   */}
                                  </div>
              )}
              <button className='btn' onClick={handleBackClick}>Back</button>
            </div>
          ) : (
            <>
              <div className="office-info1">
                <h2 className="title1">OGMCLLC</h2>
                <h2 className="title2">OGMCLLC Service Range</h2>
              </div>
              {/* <div className="office">
                <p>
                  <Link to="#" onClick={() => handleServiceClick("Corporate Governance")}>1. Corporate Governance</Link>
                  <br /><br />
                  <Link to="#" onClick={() => handleServiceClick("Accounting & Finance")}>2. Accounting & Finance</Link>
                  <br /><br />
                  <Link to="#" onClick={() => handleServiceClick("HR Consultancy")}>3. HR Consultancy</Link>
                  <br /><br />
                  <Link to="#" onClick={() => handleServiceClick("Trading & Provision of goods")}>4. Trading & Provision of goods</Link>
                </p>
              </div> */}

{/* <div className="office">
  <p>
    <Link to="#" onClick={() => handleServiceClick("Corporate Governance")}><FaBuilding className="icon1" /> 1. Corporate Governance</Link>
    <br /><br />
    <Link to="#" onClick={() => handleServiceClick("Accounting & Finance")}><FaCalculator className="icon1" /> 2. Accounting & Finance</Link>
    <br /><br />
    <Link to="#" onClick={() => handleServiceClick("HR Consultancy")}><FaPeopleCarry className="icon1" /> 3. HR Consultancy</Link>
    <br /><br />
    <Link to="#" onClick={() => handleServiceClick("Trading & Provision of goods")}><FaBoxes className="icon1" /> 4. Trading & Provision of goods</Link>
  </p>
</div> */}
<div className="office">
  <p>
    
    <Link to="#" onClick={() => handleServiceClick("Corporate Governance")}><FaBuilding className="icon1" /> <br/>Corporate Governance</Link>
  </p>
  <p>
    
    <Link to="#" onClick={() => handleServiceClick("Accounting & Finance")}><FaCalculator className="icon1" /><br/>Accounting & Finance</Link>
  </p>
  <p>
   
    <Link to="#" onClick={() => handleServiceClick("HR Consultancy")}> <FaPeopleCarry className="icon1" /><br/>HR Consultancy</Link>
  </p>
  <p>
    
    <Link to="#" onClick={() => handleServiceClick("Trading & Provision of goods")}><FaBoxes className="icon1" /><br/>Trading & Provision of goods</Link>
  </p>
</div>

            </>
          )}
        </div>
      </>
    );
  }

  export default Services;
