import React from 'react';
import '../styles/News.css'; // Import your CSS file for styling

const News = () => {
  return <>
    <div className="contact-container">
      {/* <img src={img1} alt="Slide 1" className="bg11" /> */}
        <div className="news1"></div>
        <div className="overlay"></div>
        
      <div className="carousel-textt">
  <div class="content-container">
    <div class="content">NEWS & EVENTS</div>
    <div class="navigation">
      <span>HOME</span> / <span class='contact'>NEWS & EVENTS</span>
    </div>
  </div>
  <div class="arrow-box"></div>
</div>

       
      </div>

    <div className="office-info">
        <h2>AL WAQEEFA DMCC</h2>
        <div className="office">
          
          
          <p>**No News & Events at the moment**</p>
          
        </div>
       
      </div>
    
    </>;
}

export default News;
