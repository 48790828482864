// Home.jsx

import React, { useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import { Carousel } from 'react-responsive-carousel';
import img1 from '../assets/mining.jpg';
import img2 from '../assets/oil.jpg';
import img3 from '../assets/cst.jpg';
import img4 from '../assets/govt.jpg';
import img5 from '../assets/part.jpg';
import img6 from '../assets/fin.jpg';
import img7 from '../assets/cons.jpg';
import img8 from '../assets/social.jpg';
import img9 from '../assets/transport.jpg';
import img10 from '../assets/energy.jpg';
import img11 from '../assets/health.jpg';
import img12 from '../assets/travel.jpg';
import img13 from '../assets/corp.jpg';
import img14 from '../assets/hr.jpg';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../styles/Home.css'; // Import your custom CSS for styling

const Home = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    const boxes = container.querySelectorAll('.box');

    const handleScroll = () => {
      boxes.forEach(box => {
        const boxTop = box.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        if (boxTop < windowHeight * 0.8) {
          box.classList.add('show');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <div className="home-container">
        <MyCarousel />
        <div className="container" ref={containerRef}>
          <div className="grid-container">
            <div className="box mining">
              <img src={img1} alt="mining" className="image" />
              <div className="box-content">
                <h2 className="heading-text">MINING & INDUSTRIAL</h2>
                {/* <p className="description" style={{ textAlign: 'center' }}>
                  Through this successful innovation, he paved Derrick’s path into the Mining & Industrial market. Derrick continues to develop and manufacture an array of wet and dry, fine particle separation technologies that result in increased product recovery and reduced power consumption.
                </p>
                <Link to="#" className="link">VIEW DETAILS</Link> */}
              </div>
            </div>
            <div className="box oil">
              <img src={img2} alt="Oil" className="image" />
              <div className="box-content">
                <h2 className="heading-text">OIL & GAS DRILLING</h2>
                {/* <p className="description" style={{ textAlign: 'center' }}>
                  Our solutions symbolize rugged reliability in an industry known for its intensely hazardous environment and ever-changing drilling technologies. Derrick now offers the most comprehensive and cost-effective solids control systems in the industry.
                </p>
                <Link to="#" className="link">VIEW DETAILS</Link> */}
              </div>
            </div>
            <div className="box civil">
              <img src={img3} alt="Civil" className="image" />
              <div className="box-content">
                <h2 className="heading-text">REAL STATE & CONSTRUCTION</h2>
                {/* <p className="description" style={{ textAlign: 'center' }}>
                  Our premium slurry separation equipment and modular separation plants are currently used worldwide by Civil Construction companies that require high-efficiency separation and solids dewatering in environmentally sensitive areas.
                </p>
                <Link to="#" className="link">VIEW DETAILS</Link> */}
              </div>
            </div>
            <div className="box Government">
              <img src={img4} alt="govt" className="image" />
              <div className="box-content">
                <h2 className="heading-text">GOVERNMENT</h2>
                {/* <p className="description" style={{ textAlign: 'center' }}>
                  Our premium slurry separation equipment and modular separation plants are currently used worldwide by Civil Construction companies that require high-efficiency separation and solids dewatering in environmentally sensitive areas.
                </p>
                <Link to="#" className="link">VIEW DETAILS</Link> */}
              </div>
            </div>
            <div className="box public">
              <img src={img5} alt="Public" className="image" />
              <div className="box-content">
                <h2 className="heading-text">PUBLIC, PRIVATE PARTNERSHIPS</h2>
                {/* <p className="description" style={{ textAlign: 'center' }}>
                  Our premium slurry separation equipment and modular separation plants are currently used worldwide by Civil Construction companies that require high-efficiency separation and solids dewatering in environmentally sensitive areas.
                </p>
                <Link to="#" className="link">VIEW DETAILS</Link> */}
              </div>
            </div>
            <div className="box financial">
              <img src={img6} alt="financial" className="image" />
              <div className="box-content">
                <h2 className="heading-text">FINANCIAL SERVICES</h2>
                {/* <p className="description" style={{ textAlign: 'center' }}>
                  Our premium slurry separation equipment and modular separation plants are currently used worldwide by Civil Construction companies that require high-efficiency separation and solids dewatering in environmentally sensitive areas.
                </p>
                <Link to="#" className="link">VIEW DETAILS</Link> */}
              </div>
            </div>
            <div className="box consumer">
              <img src={img7} alt="Consumer" className="image" />
              <div className="box-content">
                <h2 className="heading-text">CONSUMER</h2>
                {/* <p className="description" style={{ textAlign: 'center' }}>
                  Our premium slurry separation equipment and modular separation plants are currently used worldwide by Civil Construction companies that require high-efficiency separation and solids dewatering in environmentally sensitive areas.
                </p>
                <Link to="#" className="link">VIEW DETAILS</Link> */}
              </div>
            </div>
            <div className="box tech">
              <img src={img8} alt="Tech" className="image" />
              <div className="box-content">
                <h2 className="heading-text">TECHNOLOGY, MEDIA & COMMUNICATION</h2>
                {/* <p className="description" style={{ textAlign: 'center' }}>
                  Our premium slurry separation equipment and modular separation plants are currently used worldwide by Civil Construction companies that require high-efficiency separation and solids dewatering in environmentally sensitive areas.
                </p>
                <Link to="#" className="link">VIEW DETAILS</Link> */}
              </div>
            </div>
            <div className="box transport">
              <img src={img9} alt="Transport" className="image" />
              <div className="box-content">
                <h2 className="heading-text">TRANSPORT & LOGISTICS</h2>
                {/* <p className="description" style={{ textAlign: 'center' }}>
                  Our premium slurry separation equipment and modular separation plants are currently used worldwide by Civil Construction companies that require high-efficiency separation and solids dewatering in environmentally sensitive areas.
                </p>
                <Link to="#" className="link">VIEW DETAILS</Link> */}
              </div>
            </div>
            <div className="box energy">
              <img src={img10} alt="Energy" className="image" />
              <div className="box-content">
                <h2 className="heading-text">ENERGY, RESOURCES & UTILITIES</h2>
                {/* <p className="description" style={{ textAlign: 'center' }}>
                  Our premium slurry separation equipment and modular separation plants are currently used worldwide by Civil Construction companies that require high-efficiency separation and solids dewatering in environmentally sensitive areas.
                </p>
                <Link to="#" className="link">VIEW DETAILS</Link> */}
              </div>
            </div>
            <div className="box hosp">
              <img src={img11} alt="Hosp" className="image" />
              <div className="box-content">
                <h2 className="heading-text">HOSPITALITY, HEALTHCARE & SPORTS</h2>
                {/* <p className="description" style={{ textAlign: 'center' }}>
                  Our premium slurry separation equipment and modular separation plants are currently used worldwide by Civil Construction companies that require high-efficiency separation and solids dewatering in environmentally sensitive areas.
                </p>
                <Link to="#" className="link">VIEW DETAILS</Link> */}
              </div>
            </div>
            <div className="box travel">
              <img src={img12} alt="Travel" className="image" />
              <div className="box-content">
                <h2 className="heading-text">TRAVEL & TOURISM</h2>
                {/* <p className="description" style={{ textAlign: 'center' }}>
                  Our premium slurry separation equipment and modular separation plants are currently used worldwide by Civil Construction companies that require high-efficiency separation and solids dewatering in environmentally sensitive areas.
                </p>
                <Link to="#" className="link">VIEW DETAILS</Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// const MyCarousel = () => (
//   <Carousel
//     autoPlay
//     infiniteLoop
//     interval={1000}
//     showStatus={false}
//     showThumbs={false}
//     showArrows={true}
//     className="custom-carousel"
//   >
//     <div className="carousel-slide">
//       <img src={img1} alt="Slide 1" className="carousel-image" />
//     </div>
//     <div className="carousel-slide">
//       <img src={img2} alt="Slide 2" className="carousel-image" />
//     </div>
//     <div className="carousel-slide">
//       <img src={img3} alt="Slide 3" className="carousel-image" />
//     </div>
//     <div className="carousel-slide">
//       <img src={img4} alt="Slide 4" className="carousel-image" />
//     </div>
//     <div className="carousel-slide">
//       <img src={img5} alt="Slide 4" className="carousel-image" />
//     </div>
//     <div className="carousel-slide">
//       <img src={img6} alt="Slide 4" className="carousel-image" />
//     </div>
//     <div className="carousel-slide">
//       <img src={img7} alt="Slide 4" className="carousel-image" />
//     </div>
//   </Carousel>
// );
const MyCarousel = () => (
  <Carousel
    autoPlay
    infiniteLoop
    interval={1000}
    showStatus={false}
    showThumbs={false}
    showArrows={true}
    className="custom-carousel"
  >
    <div className="carousel-slide">
      <img src={img13} alt="Slide 1" className="carousel-image" />
      <div className="carousel-text">Slide 1 Name</div>
      <div className="carousel-text1">CORPORATE GOVERNANCE</div>
    </div>
    <div className="carousel-slide">
      <img src={img6} alt="Slide 2" className="carousel-image" />
      <div className="carousel-text">Slide 2 Name</div>
      <div className="carousel-text1">ACCOUNTING & FINANCE</div>
    </div>
    <div className="carousel-slide">
      <img src={img13} alt="Slide 3" className="carousel-image" />
      <div className="carousel-text">Slide 3 Name</div>
      <div className="carousel-text1">HR CONSULTANCY</div>
    </div>
    <div className="carousel-slide">
      <img src={img9} alt="Slide 3" className="carousel-image" />
      <div className="carousel-text">Slide 3 Name</div>
      <div className="carousel-text1">TRADING</div>
    </div>

  </Carousel>
);

export default Home;
